module.exports = {
	"reports": {
		"group_title": "Отчеты",
		"common": {
			"date": "Дата",
			"week": "Неделя",
			"month": "Месяц",
			"year": "Год",
			"currency": "Валюта",
			"order_currency": "Валюта заявки",
			"billing_currency": "Валюта платежа",
			"processor": "Процессинг",
			"system": "ПС",
			"project": "Проект",
			"type": "Тип",
			"locale": "Локаль",
			"customer_country": "Страна игрока",
			"token_country": "Страна по IP",
			"jurisdiction": "Юрисдикция",
			"bank": "Банк",
			"platform": "Платформа",
			"user": "Пользователь",
			"customer_area": "Регион игрока",
			"token_area": "Регион по IP",
			"load_report_message": "Выберите необходимые фильтры и нажмите кнопку Показать",
			"widget_mode": "Режим виджета",
			"billing_type": "Тип платежа",
			"ftd": "FTD",
			"refcode": "Рефкод",
			"purse_country": "Страна кошелька",
			"failure_type": "Тип отказа",
			"failure_subtype": "Подтип отказа",
			"failure_code": "Код отказа",
			"failure_message": "Сообщение отказа",
			"vwo_variation": "Вариация VWO",
			"card_type": "Тип карты"
		},
		"deposit_passability": {
			"title": "Проходимость депозитов",
			"total_count": "Всего депозитов",
			"success_count": "Оплачено",
			"user_count": "Пользователей всего",
			"successful_user_count": "Пользователей успешных",
			"terminal": "Отказ терминала",
			"timeout": "Таймаут",
			"success_count_without_timeouts": "Оплачено без учета таймаутов",
			"processing_time_without_timeouts": "Среднее время обработки заявки без учета таймаутов"
		},
		"withdraw_passability": {
			"title": "Проходимость выплат",
			"total_count": "Всего выплат",
			"success_count": "Успешных",
			"rejected_count": "Отклонённых"
		},
		"passability": {
			"processing_time": "Среднее время обработки заявки",
			"user_failure": "Отказ пользователя",
			"processor_decline": "Отказ процессинга",
			"insufficient_funds": "Недостаточный баланс"
		},
		"fees": {
			"title": "Комиссии",
			"deposit_amount": "Сумма депозитов",
			"deposit_billing_amount": "Сумма депозитов (платеж)",
			"deposit_count": "Количество депозитов",
			"withdraw_amount": "Сумма выплат",
			"withdraw_billing_amount": "Сумма выплат (платеж)",
			"withdraw_count": "Количество выплат",
			"deposit_fee_external_amount": "Комиссия (внешняя, IN)",
			"withdraw_fee_external_amount": "Комиссия (внешняя, OUT)",
			"deposit_fee_amount": "Комиссия (расчетная, IN)",
			"deposit_fee_percent": "% комиссии (IN)",
			"withdraw_fee_amount": "Комиссия (расчетная, OUT)",
			"withdraw_fee_percent": "% комиссии (OUT)",
			"fee_amount": "Комиссия (IN + OUT)",
			"fee_percent": "% комиссии (IN + OUT)",
			"fee_sum": "% комиссии (оборот)"
		},
		"customers_turnover": {
			"title": "Оборот пользователей",
			"player": "Игрок",
			"deposit_amount": "Сумма депозитов",
			"withdraw_amount": "Сумма выплат"
		},
		"transition_history": {
			"title": "Выплаты: история статусов",
			"page_title": "История статусов заявок",
			"old_status": "Старый статус",
			"new_status": "Новый статус",
			"change_interval": "Время на изменение статуса",
			"created_at": "Дата изменения статуса",
			"order_created_at": "Дата создания заявки"
		},
		"transactions": {
			"title": "Отчет по транзакциям",
			"deposit_amount": "Сумма депозитов",
			"deposit_count": "Количество депозитов",
			"withdraw_amount": "Сумма выплат",
			"withdraw_count": "Количество выплат",
			"deposit_fee_amount": "Комиссия (расчетная, IN)",
			"deposit_fee_external_amount": "Комиссия (внешняя, IN)",
			"withdraw_fee_amount": "Комиссия (расчетная, OUT)",
			"withdraw_fee_external_amount": "Комиссия (внешняя, OUT)",
			"fee_sub": "% комиссии (инаут)",
			"fee_sum": "% комиссии (оборот)",
			"refund_amount": "Сумма рефандов",
			"refund_count": "Количество рефандов",
			"processor_channel": "Канал процессинга",
			"failed_deposit_count_without_timeouts": "Количество отказанных депозитов (без учета таймаутов)",
			"failed_withdraw_count_without_timeouts": "Количество отказанных выплат (без учета таймаутов)"
		},
		"global_orders": {
			"title": "Общий отчет по заявкам",
			"total_deposit_count": "Количество депозитов",
			"successful_deposit_count": "Количество успешных депозитов",
			"successful_deposit_sum": "Сумма успешных депозитов",
			"successful_deposit_billing_sum": "Сумма успешных депозитов (платеж)",
			"deposit_fee_sum": "Сумма комиссий (расчетная, IN)",
			"deposit_customer_count": "Количество игроков по депозитам",
			"successful_deposit_customer_count": "Количество игроков по успешным депозитам",
			"successful_withdraw_count": "Количество успешных выплат",
			"successful_withdraw_sum": "Сумма успешных выплат",
			"successful_withdraw_billing_sum": "Сумма успешных выплат (платеж)",
			"withdraw_fee_sum": "Сумма комиссий (расчетная, OUT)",
			"withdraw_fee_external_sum": "Сумма комиссий (внешняя, OUT)",
			"deposit_fee_external_sum": "Сумма комиссий (внешняя, IN)"
		},
		"processor_external_actions": {
			"title": "Финансовый отчет",
			"merchant_name": "Мерчант",
			"payment_method": "Платежный метод",
			"payment_type": {
				"title": "Тип оплаты",
				"deposit": "Депозит",
				"withdraw": "Выплата"
			},
			"total_count": "Общее количество",
			"total_volume": "Общий объем",
			"total_fee": "Общая комиссия",
			"total_fee_percentage": "Общая комиссия (%)",
			"authentication_fixed_fee": "Authentication Fixed Fee",
			"authorization_fixed_fee": "Authorization Fixed Fee",
			"intelligent_acceptance_fixed_fee": "Intelligent Acceptance Fixed Fee",
			"interchange_fixed_fee": "Interchange Fixed Fee",
			"network_token_provisioning_fixed_fee": "Network Token Provisioning Fixed Fee",
			"network_token_update_fixed_fee": "Network Token Update Fixed Fee",
			"payout_success_fixed_fee": "Payout Success Fixed Fee",
			"payout_success_variable_fee": "Payout Success Variable Fee",
			"premium_variable_fee": "Premium Variable Fee",
			"refund_fixed_fee": "Refund Fixed Fee",
			"scheme_fixed_fee": "Scheme Fixed Fee",
			"scheme_variable_fee": "Scheme Variable Fee"
		}
	}
};